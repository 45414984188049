import { withTheme } from "@emotion/react";
import { red, blue, green } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const bgColors = {
  color1: "#ffe16f",
  color2: "#ffa200",
  color3: "#827191",
  color4: "#7D1D3F",
};

const mainColors = {
  primary: "#ffa200",
  secondary: "#31211C",
};

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: mainColors.primary,
    },
    secondary: {
      main: mainColors.secondary,
    },
    error: {
      main: blue.A400,
    },
    background: {
      default: "#ffe16f",
      paper: "#ffa200", //same as main
    },
    action: {
      //used for disabled buttons
      disabledBackground: mainColors.secondary,
      disabled: "#FFFFFF",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ["Roboto", "sans-serif"].join(","),
    fontWeightRegular: 400,
    h1: {
      fontFamily: ["Architects Daughter"].join(","),
    },
    h2: {
      fontFamily: ["Architects Daughter"].join(","),
    },
    h3: {
      fontFamily: ["Architects Daughter"].join(","),
    },
    h4: {
      fontFamily: ["Architects Daughter"].join(","),
    },
    h5: {
      fontFamily: ["Architects Daughter"].join(","),
    },
    h6: {
      fontFamily: ["Architects Daughter"].join(","),
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:last-of-Type": {
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          },
        },
      },
    },
  },
  // @ts-ignore
  custom: {
    darkBgColor: {
      bgcolor: bgColors.color2,
    },
    lightSectionStyle: {
      borderRadius: 0,
      bgcolor: bgColors.color1,
      boxShadow: 1,
      p: 0,
      position: "relative",
    },
    darkSectionStyle: {
      borderRadius: 0,
      bgcolor: bgColors.color2,
      boxShadow: 1,
      p: 2,
    },
    termsSectionStyle: {
      bgcolor: bgColors.color1,
      p: 4,
    },
    darkButton: {
      bgcolor: mainColors.secondary,
      color: "#FFFFFF",
    },
    boldTextLargeCentered: {
      component: "div",
      fontWeight: 600,
      fontSize: 22,
      textAlign: "center",
      fontFamily: ["Architects Daughter"].join(","),
    },
    italicTextCentered: {
      component: "div",
      textAlign: "center",
      fontStyle: "italic",
    },
    textCentered: {
      component: "div",
      textAlign: "center",
    },
    boldTextLarge: {
      component: "div",
      fontWeight: 600,
      fontSize: 22,
      fontFamily: ["Architects Daughter"].join(","),
    },
  },
});

export default theme;
